import { createApp } from 'vue';
import App from './App.vue';
import { router } from './router';
// 引用的阿里巴巴矢量图标库，使用方式：挑选相应图标并获取类名，应用于页面：<span class="iconfont icon-xxx"></span>
import "./assets/font/iconfont.css";


const app = createApp(App);
app.use(router);
app.mount('#app');

