import {createRouter,  createWebHistory} from 'vue-router';

const routes = [
  {
    name: 'notFound',
    path: '/:path(.*)+',
    redirect: {
      name: 'home',
    },
  },
  {
    name: 'home',
    path: '/home',
    component: () => import('./view/home'),
    meta: {
      title: '框架首页',
    },
  },
  {
    name: 'dome',
    path: '/dome',
    component: () => import('./view/dome'),
    meta: {
      title: '临时',
    },
  },
  {
    name: 'shop-home',
    path: '/shop-home',
    component: () => import('./view/shop-home'),
    meta: {
      title: '首页',
    },
  },
  {
    name: 'shop-class',
    path: '/shop-class',
    component: () => import('./view/shop-class'),
    meta: {
      title: '分类',
    },
  },
  {
    name: 'shop-buys',
    path: '/shop-buys',
    component: () => import('./view/shop-buys'),
    meta: {
      title: '购物车',
    },
  },
  {
    name: 'shop-ours',
    path: '/shop-ours',
    component: () => import('./view/shop-ours'),
    meta: {
      title: '我的',
    },
  },
  {
    name: 'auth',
    path: '/auth',
    component: () => import('./view/shop-ours/auth.vue'),
    meta: {
      title: '授权',
    },
  },
  {
    name: 'pay',
    path: '/pay',
    component: () => import('./view/shop-ours/pay.vue'),
    meta: {
      title: '支付',
    },
  },
  {
    name: 'shop-address',
    path: '/shop-address',
    component: () => import('./view/shop-address'),
    meta: {
      title: '收货地址',
    },
  },
  // 移动
  {
    name: 'mb-home',
    path: '/mb-home',
    component: () => import('./view/mb-home'),
    meta: {
      title: '首页',
    },
  },
  {
    name: 'mb-class',
    path: '/mb-class',
    component: () => import('./view/mb-class'),
    meta: {
      title: '分类',
    },
  },
  {
    name: 'mb-equity',
    path: '/mb-equity',
    component: () => import('./view/mb-equity'),
    meta: {
      title: '权益',
    },
  },
  {
    name: 'mb-ours',
    path: '/mb-ours',
    component: () => import('./view/mb-ouers'),
    meta: {
      title: '我的',
    },
  },
  {
    name: 'mb-vfp-setmeal',
    path: '/mb-vfp-setmeal',
    component: () => import('./view/mb-vfp-setmeal'),
    meta: {
      title: '我的套餐',
    },
  },
  
];

const router = createRouter({
  routes,
  history: createWebHistory(),
});

router.beforeEach((to, from, next) => {
  const title = to.meta && to.meta.title;
  if (title) {
    document.title = title;
  }

  let fullPath = to.fullPath
  if (to.name === 'auth') {
    next()
    return
  }
  let openId = localStorage.getItem('open-id');
  // let thirdSession = localStorage.getItem('third-session');
  if (!openId) {
    //保存当前路由地址，授权后还会跳到此地址
    sessionStorage.setItem('wxRedirectUrl', fullPath)
    //请求微信授权,并跳转到 /auth 路由
    let appId = 'wx95950b26c2eb88ac'
    localStorage.setItem("app-id", appId);

    let redirectUrl = encodeURIComponent('https://shop.crystal-map.com')
    //判断是否为正式环境
    if (window.location.origin.indexOf('shop.crystal-map.com') !== -1) {
      redirectUrl = encodeURIComponent('https://shop.crystal-map.com/auth')
    } else {
      redirectUrl = encodeURIComponent('http://localhost:8080/auth')
    }
    window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appId}&redirect_uri=${redirectUrl}&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect&connect_redirect=1`
  } else {
    next()
  }
  // next();
});

export { router };
